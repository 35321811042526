<template>
  <main>
    <PageTitleContainer>
      <template #default>
        <PageTitle>Varietà di uva</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'inventory.blends.add' }">Aggiungi</router-link>
      </template>
    </PageTitleContainer>

    <FetchList :loading="loading">
      <div class="container">
        <AwlTable v-if="blends && blends.length > 0" :heading="tableHeadings" :rows="blends" striped>
          <template #actions-content="{ row }">
            <ActionEdit :to="{ name: 'inventory.blends.edit', params: { id: row.id } }" />
            <ActionShow class="ms-2" :to="{ name: 'inventory.blends.show', params: { id: row.id } }" />
          </template>
        </AwlTable>
      </div>
    </FetchList>
  </main>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import FetchList from '@/views/components/Layout/FetchList.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';
import ActionEdit from '@/views/components/Actions/ActionEdit.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listBlends()
    .then(res => next(vm => {
      vm.blends = res?.data?.blends || [];
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { FetchList, AwlTable, ActionShow, ActionEdit, PageTitleContainer, PageTitle },
  data () {
    return {
      loading: false,
      blends: null,
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'name', label: 'Name' },
      ];
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
